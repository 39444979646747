.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.navbar-edit {
  background-color: #333333;
  position: fixed;
  text-align: center;
  color: white;
  min-height: 60px;
}

.nav-item.dropdown .dropdown-menu {
 color: white;
  background-color: transparent;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  background-color: #333333;
  opacity: 1;
  text-decoration: none;
  color: white !important;
}

#collasible-nav-dropdown{
  color: white;
  background-color: transparent;
  height: 60px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  width: 4px;
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 

::-webkit-scrollbar-thumb {
  width: 40px;
  background: #333333; 
  border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
  width: 40px;
  background: #333333; 
}

.titregris{
  color:rgb(145, 143, 143);
  font-weight:bold;
  text-align: left;
  margin-left: 5%;
}

.textcgu{
  color:rgb(145, 143, 143);
  text-align: left;
  margin-left: 5%;
}

.languetype{
  align-items: center;
  justify-content: center;
  color: white !important;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 1em;
}

.languetype:hover {
  color: #2A83E8 !important;
  transform: scale(1.1);
  text-decoration: none !important;
  font-size: 1.1em;
  cursor: pointer;
}

.listearticle{
  margin-left: 2%;
}

.color-me{
  color:blue;
}

.accueil {
  height: 100%; 
  align-items: center;
  justify-content: center;
  display: flex;
}

.nav-link{
  color: white !important;
  font-size: 18px;
}

.nav-link--active {
  color: white !important;
  transform: scale(1.1);
}

.nav-link:hover {
  color: #2A83E8 !important;
  transform: scale(1.1);
  text-decoration: none !important;
  font-weight: 18px;
  font-size: 20px;
}

.navDropdownitems:hover {
  color: #2A83E8 !important;
  transform: scale(1.1);
  text-decoration: none !important;
  font-weight: 10px;
  
}

.dropdown-menu a{
  color: white !important;
 }

.dropdown-menu a:hover{
  background: none;
  color: #2A83E8 !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
 
.new-line {
white-space: pre-line;
}

.rotate45 {
  -webkit-transform: rotate(-45deg); 
  -moz-transform: rotate(-45deg);    
  transform:rotate(-45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.verticalcenter {
  vertical-align: middle!important;
  display : inline-block;
}

.menu {
  background-color:#333333;
  width: 100%;
}

.table tbody tr:hover td, .table tbody tr:hover th {
  background-color:rgba(189, 195, 199, 0.7);
  color: white !important;
}

a {
  text-decoration: none;
  color:white;
}

a:hover {
  color: #2A83E8 !important;
}


.footer{
  background-color: #1e1e1e;
  width: 100%;
}

.contact {
  height: 100%; 
}

.backgroundblur {
  filter: blur(8px);

  height: 100%; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}



.textombrenoir {
  text-shadow: -1px 1px 0 #000,
  1px 1px 0 #000,
 1px -1px 0 #000,
-1px -1px 0 #000;
}

.textombreblanc{
  text-shadow: -1px 1px 0 #ffffff,
  1px 1px 0 #ffffff,
 1px -1px 0 #ffffff,
-1px -1px 0 #ffffff;
}

.dashboard{
  margin-top: 60px;
  padding-bottom: 60px;
  min-height: 100vh;
  background: #333333;

}

.datastat{
  width: 200px;
  height: 70px;
  border-radius: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 3px solid;
  margin-top: 30px;
  margin-right: 20px;
}

.datauser{
  width: 200px;
  height: 50px;
  margin-right: 20px;
  line-height: 30px;
}

.linechart{
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: auto;
  border-radius: 26px;
  background-color: #252730;
}

.linechartmobile{
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: auto;
  border-radius: 26px;
  background-color: #252730;
}


.alignrow {
    display: flex;
    justify-content: space-between;
}

.modal-footer {  
    justify-content: space-between;  
  }

  .Mapscreen{
    overflow-y: hidden;
    overflow-x: hidden;
    overflow: hidden;
  }

  .cartemap{
    margin-top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    width:'300px';
    height:'300px';
  }

  .centrervue {
    justify-content: center;
    align-items: center;
  }

  .reponsediarization {
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    height:58VH;
    /*background-color: #E8F4FF;*/
  }

  .sentimentchart{
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    height:58VH;
    background-color: #f5f6fa;
  }

  .textleft{
    width: 100wh;
    height: auto;
    background-color:#C0D3E3;
    color: black;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    text-align: left;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }

  .textright{
    width:100wh;
    height: auto;
    background-color:#2A83E8;
    color: white;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    text-align: right;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }
  
  .centerunsaid {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .rightunsaid {
    margin: 0;
    position: absolute;
    left: 60%;
    -ms-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
  }

  .rightunsaidprint {
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
  }

  .leftunsaid {
    margin: 0;
    position: absolute;
    right: 30%;
    -ms-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
  }

  .rightunsaidmobile {
    margin: 0;
    position: absolute;
    left: 50px;
    -ms-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
  }

  .leftunsaidmobile {
    margin: 0;
    position: absolute;
    left: -50px;
    -ms-transform: translate(-100%, -100%);
    transform: translate(-100%, -100%);
  }

  .champpositif { 
    width: 20%;
    height: 100px;
    background-color: #198754;
    color: white;
    position: relative;
    border-radius: 10px;
    margin: 20px;
  }

  .champneutre{
    width: 20%;
    height: 100px;
    background-color: #333333;
    color: white;
    position: relative;
    border-radius: 10px;
    margin: 20px;
  }

  .champnegatif{
    width: 20%;
    height: 100px;
    background-color: #dc3545;
    color: white;
    position: relative;
    border-radius: 10px;
    margin: 20px;
  }

  .modalscroll{
    overflow-y:hidden;
    overflow-x:hidden;
    padding-right:0 !important;
    overflow: hidden;
    max-height: 80vh;
  }

  .champpositifmodal { 
    text-align: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 25%;
    height: 60px;
    background-color: #00934c;
    color: white;
    position: relative;
    border-radius: 15px;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
   }

  .champneutremodal {
    text-align: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 25%;
    height: 60px;
    background-color: #e89900;
    color: white;
    position: relative;
    border-radius: 15px;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }

  .champnegatifmodal {
    text-align: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 25%;
    height: 60px;
    background-color: #e40001;
    color: white;
    position: relative;
    border-radius: 15px;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }

  .champpositifglobal { 
    text-align: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 50%;
    height: 80px;
    background-color: #00934c;
    color: white;
    position: relative;
    border-radius: 15px;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
   }

  .champneutreglobal {
    text-align: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 50%;
    height: 80px;
    background-color: #e89900;
    color: white;
    position: relative;
    border-radius: 15px;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }

  .champnegatifglobal {
    text-align: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 50%;
    height: 80px;
    background-color: #e40001;
    color: white;
    position: relative;
    border-radius: 15px;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }
  
  .thematiquevalue{
    background-color: #333333;
    border-radius: 15px;
    margin-left: 20px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }

  hr.lineseparator {
    border: 2px;
    border-radius: 1px;
  }

  hr.lineseparatorspeaker {
    margin-left: 10%;
    border: 2px;
    border-radius: 1px;
    width: 50vh;
  }

  .champpositifsentimentmodal { 
    text-align: center;
    width: 40px;
    height: auto;
    background-color: #00934c;
    color: white;
    border-radius: 15px;
    margin-left: -30px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }

  .champneutresentimentmodal {
    text-align: center;
    width: 40px;
    height: auto;
    background-color: #e89900;
    color: white;
    border-radius: 15px;
    margin-left: -10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
   }

  .champnegatifsentimentmodal {
    text-align: center;
    width: 40px;
    height: auto;
    background-color: #e40001;
    color: white;
    border-radius: 15px;
    margin-left: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }

  .champpositifsentimentmodalmobile { 
    text-align: center;
    width: 40px;
    height: auto;
    background-color: #00934c;
    color: white;
    border-radius: 15px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }

  .champneutresentimentmodalmobile {
    text-align: center;
    width: 40px;
    height: auto;
    background-color: #e89900;
    color: white;
    border-radius: 15px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
   }

  .champnegatifsentimentmodalmobile {
    text-align: center;
    width: 40px;
    height: auto;
    background-color: #e40001;
    color: white;
    border-radius: 15px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }

  .btnpositifmodal{
    background-color: #00934c;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }
  
  .btnneutremodal{
    background-color: #e89900;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }
  
  .btnmixedmodal{
    background-color: #929292;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }
  
  .btnnegatifmodal{
    background-color: #e40001;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }

  .btntransparent{
    background-color: transparent;
    border-width: 1;
    border-color: #8c8c8c;
  }

  .leaflet-container {
    height: 100%;
    width: 100%;
  } 

  .inputselectfile{
    background-color:#8c8c8c;
    color:#fff;
    border:1px solid #8c8c8c;
    padding:5px;
    border-radius:6px;
    box-shadow:1px 1px 3px #000;
    margin:10px;

  }

  .input-file{
    display:none;

  }
  .input-btn{
    background-color:#8c8c8c;
    color:#fff;
    border:1px solid #8c8c8c;
    padding:5px;
    border-radius:6px;
    box-shadow:1px 1px 3px #000;
    margin:10px;
  }
  .input-btn:hover{
    background-color:#c9cccb;
  }

  input{
    height: 30px;
  }

.btnpositif{
  background-color: #198754;
}

.btnneutre{
  background-color: #333333;
}

.btnmixed{
  background-color: #929292;
}

.btnnegatif{
  background-color: #dc3545;
}

.btn-gris{
  background-color: #dcdde1;
}
  
.btn-circle {
    width: 50px;
    height: 50px;
    border-radius: 20%;
    text-align: center;
    color: white;
    border-width: 1;
    border-color: white;
    margin-right: 0px;
}

.btn-circle:hover {
    width: 55px;
    height: 55px;
    border-radius: 20%;
    text-align: center;
    border-color: white;
}

.btn-sentiment {
  text-align: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  color: white;
  font-weight: bold;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.listedatasentiment {
  border-collapse: collapse;
  overflow-y: scroll;
  color:#6e6d6d;
  width:100wh;
  overflow-x: hidden;
}

.listesentiment {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  align-content: space-between;
  align-items: center;
  justify-content: center;
  scroll-behavior: smooth;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.listesentimentprint {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  align-content: space-between;
  align-items: center;
  justify-content: center;
}

.sentimentglobal{
  display: flex;
  flex-wrap: wrap;
  height: auto;
  align-content: space-between;
  align-items: center;
  justify-content: center;
}

/*
table {
  border-collapse: collapse;
  overflow-x: hidden;
  color:#6e6d6d;
  width:50vh;
}
thead {
  background-color: #EFEFEF;
}
thead, tbody {
  display: block;
}
tbody {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 60vh;
}

td, th {
  min-width: 100px;
  height: 60px;
  border: dashed 1px lightblue;
  overflow:hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

Table {
  border-collapse: collapse;
  overflow-x: scroll;
}

.TableCell:hover{
  background-color: #6e6d6d;
}

*/

.loadertranscription {
  margin: -20px 0 0 -20px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #00934c;
  border-right: 3px solid #dc3545;
  border-bottom: 3px solid #e89900;
  border-left: 3px solid #2A83E8;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

Thead {
  background-color: #EFEFEF;
}

.tableborder{
  border-bottom: solid;
  border-color: #EFEFEF;
  border-width: 2px;
}

.maxwidthline{
  max-width: 20ch;
  overflow-wrap:break-word;
}

.container{
width:100%;
}

.centercontent{
  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  
}
.centerdata{
  margin: 0 auto;
  align-self: center;
  width:150px;
}

.audioplayer{
  position: fixed; 
  right: 20%;
  z-index: 99999;
}


.audioplayermodal{
  position: fixed;
  width:400px;
  height: 40px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; 
  align-content: center;
}

.audioplayermodalmobile{
  position: fixed;
  width:400px;
  height: 40px;
  margin-left:35%;
  margin-top: 50px;
  transform: translate(-50%, -50%);
	text-align: center; 
  align-content: center;
  justify-content: center;
}

.audioplayermodalcr{
  width:400px;
  height: 40px;
  margin-left:50%;
  transform: translate(-50%, -50%);
	text-align: center; 
  align-items: center;
  justify-content: center;
  position: fixed;
}

.modaldetailsdata{
  overflow-x:hidden;
  background-color: #E2EDF3 !important;
  height: 96vh;
}

.modaldetailsticket{
  overflow-x:hidden;
  min-height: 50vh;
  max-height: 80vh;
}

.compterendu{
  background-color: #E2EDF3 !important;
}

.input-field{
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-field input{
  border: show;
  outline: none;
  font-size: 18px;
  text-indent: 2px; 
}

.input-field span{
  cursor: pointer;
}

.inputtable {
  text-align: center;
  background: #F5F5F5;
  border-radius: 7.46431px;
  border: 0px;
  color: rgba(0, 0, 0, 0.35);
}

.inputtable:focus {
  color: black;
  outline: none;
  text-align: center;
}

.footer-content {
  position: absolute;
  text-align: center;
  align-items: center;
  justify-content: center;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

.footermobile{
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  align-items: center;

}

.tranparentback{
  background-color: transparent;
  border: none;
  padding: 5px;
  border-color: transparent;
  border-bottom: 1px solid #ccc;
}

.tranparentback:focus{
  background-color: transparent;
  padding: 5px;
  outline: none;
  border-bottom: 0px solid #ccc;
}

.icondahsboard{
  margin-right: 20px;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 16px;
  top: 14px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
  border-radius: 5px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #2A83E8;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 50px;
  width: 50px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #333333;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  width:400px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #333333;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: #d1d1d1;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #ffffff;
}

.footermenu{
  position:absolute;
  bottom:0px;
}

.wrapper {
  height: 500px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, .5); border-radius: 20px;
  backdrop-filter: blur(20px);
  box-shadow: 0 0 30px rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
}


.wrapper .form-box {
  width: 100%;
  padding: 40px;
}


.form-box h2 {
  font-size: 2em;
  color: #162938;
  text-align: center;
}

.input-box {
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #162938;
  margin: 30px 0;
}

.input-box label { position: absolute;
  top: 50%;
  left: 5px;
  transform:
  translateY(-50%);
  font-size: 1em;
  color: #162938;
  font-weight: 500;
  pointer-events: none;
  transition: .5s;
}

.input-box
input:focus~label,
.input-box input:valid~label {
top: -5px;
}

.input-box input {
  width: 90%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
}

.input-box .icon{
    position: absolute;
    right: 8px;
    font-size: 1.2em;
    color: white;
    line-height: 57px;
  }


.input-box {
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #2A83E8;
  margin: 30px 0;
}

.input-box label { position: absolute;
  top: 50%;
  left: 5px;
  transform:
  translateY(-50%);
  font-size: 1em;
  color: #162938;
  font-weight: 500;
  pointer-events: none;
  transition: .5s;
}

.input-box
input:focus~label,
.input-box input:valid~label {
top: -5px;
}

.input-box input {
  width: 90%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
}

.input-box .icon{
    position: absolute;
    right: 8px;
    font-size: 1.2em;
    color: white;
    line-height: 57px;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    width: 100px;
  }

  .dateinput{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }


  .inputfind {
    margin-bottom: 30px;
    width: 90%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-repeat: no-repeat;
    padding: 12px 20px 15px 10px;
    transition: width 0.4s ease-in-out;
  }
  
  .inputfind:focus {
    width: 90%;
    border: 2px solid #162938;
  }

  .pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .pagination > .active > a{
    background-color: #2A83E8 ;
    border-color: #2A83E8 ;
    color: #2A83E8;
  }
  .pagination > li > a{
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #2A83E8 ;
    border-radius: 4px;
    color: #fff !important;
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: #2A83E8;
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset;
  }

  .paginationmobile{
    width:90%;
    border-radius: 4px;
    background-color: #162938;
    position:fixed;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#2a83e8;
    margin: 5% auto;
    left: 0;
    right: 0;
  }

  .loginstyle{
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: left;
    width:100%;
    height: 100vh;
      background: 
      -webkit-linear-gradient(rgb(42, 131, 232), rgb(42, 131, 232, 0.0) 0%),
     -webkit-linear-gradient(-25deg, rgba(42, 131, 232,1) 45%, rgba(255, 255, 255, 1) 0%);
  }

  .iconsocial {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    left: 50%;
    bottom: -20px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
  }

.footerWrap {
    width:100%;
    position:fixed;
    bottom: 0px;
}
.footercontent {
    width:400px;
    margin:auto;
}

.footer p {float:left; width:100%; text-align:center; }

.titlelogin{
  text-align: left;
  align-items: left;
  justify-content: start;
}

.loader{
  position:absolute;
  top:40%;
  left:48%;
  text-align: center;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.loaderloading{
  z-index: 9999;
  margin: 20px 0 0 0px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #f3f3f3;
  border-right: 3px solid #2A83E8;
  border-bottom: 3px solid #f3f3f3;
  border-left: 3px solid #2A83E8;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

}

.typeabonnement {
  position:absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 160px;
  background: #333333;
  text-align: center;
  border-radius: 23px 23px 0px 0px;
}

.timeabonnement{
  position:absolute;
  left: 0;
  margin-top: 100px;
  width: 100%;
  height:190px;
  background: #33353a;
  text-align: center;
  padding-top: 0px;
}

.abonnementdetails{
  position:absolute;
  left: 0;
  margin-top: 140px;
  width: 100%;
  height: auto;
  background: #F0F0F0;
  text-align: center;
  color: black;
  border-radius: 0px 0px 23px 23px;
}

.boutonsubscribe {
  position:absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: #2A83E8;
  color: white;
  border-radius: 23px 23px 23px 23px;
  text-align: center;
}

.boutonsubscribe {
  cursor: pointer;
}

.rgpdborder{
  padding:2%;
  border-radius: 23px 23px 23px 23px;
  box-shadow: 2px 0px 4px 4px rgba(0, 0, 0, 0.4);
}

#solution{
  background-color: rgb(238,238,238);
}
.listsolution {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  align-items: center;
  justify-content: center;
}


.cardlist{
  filter: drop-shadow(0px 40px 40px rgba(16, 52, 87, 0.15));
}

.cardinfopayment{
  margin: 20px;
  height: auto;
  border-radius: 6px;
  box-sizing: content-box;
  border: 2px solid #2A83E8;
}

.cardpayment{
  margin: 20px;
  min-height: 400px;
  border-radius: 20px;
  box-sizing: content-box;
  border: 2px solid #2A83E8;
}

.radiusmodal div{
  border-radius: 20px !important;
}

.radiusmodallist {
  border: 2px solid #2A83E8;
}

.boutonpay {
  position:absolute;
  bottom: 20;
  color: white;
  border-radius: 23px 23px 23px 23px;
  text-align: center;
}

.solutiondata{
  background-color: rgb(238,238,238);
}

.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
}
  
.check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #2A83E8;
}
    
.check-icon::before {
        top: 3px;
        left: -2px;
        width: 30px;
        transform-origin: 100% 50%;
        border-radius: 100px 0 0 100px;
    }
      
.check-icon::after {
    top: 0;
    left: 30px;
    width: 60px;
    transform-origin: 0 50%;
    border-radius: 0 100px 100px 0;
    animation: rotate-circle 4.25s ease-in;
}

.check-icon::before, .check-icon::after {
    content: '';
    height: 100px;
    position: absolute;
    background: #FFFFFF;
    transform: rotate(-45deg);
}

.icon-line {
    height: 5px;
    background-color: #2A83E8;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
}
    
.icon-line.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 1.25s;
    }
    
    .icon-line.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 1.25s;
    }


.icon-circle {
    top: -4px;
    left: -4px;
    z-index: 10;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    box-sizing: content-box;
    border: 4px solid #2A83E8;
}

.icon-fix {
    top: 8px;
    width: 5px;
    left: 26px;
    z-index: 1;
    height: 85px;
    position: absolute;
    transform: rotate(-45deg);
    background-color: #FFFFFF;
}

@keyframes rotate-circle {
  0% {
      transform: rotate(-45deg);
  }
  5% {
      transform: rotate(-45deg);
  }
  12% {
      transform: rotate(-405deg);
  }
  100% {
      transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
      width: 0;
      left: 1px;
      top: 19px;
  }
  54% {
      width: 0;
      left: 1px;
      top: 19px;
  }
  70% {
      width: 50px;
      left: -8px;
      top: 37px;
  }
  84% {
      width: 17px;
      left: 21px;
      top: 48px;
  }
  100% {
      width: 25px;
      left: 14px;
      top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
      width: 0;
      right: 46px;
      top: 54px;
  }
  65% {
      width: 0;
      right: 46px;
      top: 54px;
  }
  84% {
      width: 55px;
      right: 0px;
      top: 35px;
  }
  100% {
      width: 47px;
      right: 8px;
      top: 38px;
  }
}

.datacenter{
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  justify-content: center;
  align-items: center;
}

.contentcenter{
  text-align: center;
  justify-content: center;
  align-items: center;
}